<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="item in items"
        :key="item.id"
        :to="{ name: item.routeName }"
        class="text-uppercase"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        {{ item.description }}

        <span v-if="item.id === 1" class="ml-2">
          <feather-icon
            icon
            :badge="S_COUNTER_SELLERS > 99 ? '99+' : S_COUNTER_SELLERS"
            badge-classes="badge-important"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
import data from "./data/db.json";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: data,
      tab: 1,
    };
  },  
  computed: {
    ...mapGetters({
      S_COUNTER_SELLERS: "SellerPermissionsStore/S_COUNTER_SELLERS",
    }),
  },
};
</script>
